
import { Options, Vue } from 'vue-class-component';
import { ExhibitionClient } from '@/services/services';
import { StorageServices } from '@/services/StorageServices';
import { getArts, getArtsNumber, getStructurePath } from '@/utils';
import Share from '@/components/share.vue';
import GiveFeedback from '@/components/giveFeedback.vue';
import InsertCodeOverlay from '@/components/insertCodeOverlay.vue';
import LocationAreaDetailModal from '@/components/modals/locationAreaDetailModal.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        Share,
        GiveFeedback,
        InsertCodeOverlay
    }
})
export default class Exhibition extends Vue {

    mostra: OM.GetExhibitionVm = new OM.GetExhibitionVm();
    sections: OM.ExhibitionSectionVM[] = [];

    showMore: boolean = false;
    needLessButton: boolean = false;
    showPinCodeOverlay: boolean = false;

    showArtworksOrganized: boolean = true;

    // beforeRouteEnter(to, from, next){        
    //     next((vc: Exhibition) => {
    //         vc.mostraImage = store.state.mostra.imageUrl;
    //         vc.mostraDescription = store.state.mostra.description;
    //         vc.$nextTick(() => {
    //             let exhibitionDescriptionEl = <HTMLElement>vc.$refs.mostraDescription;
    //             let windowTreshold = window.innerHeight * 19 / 100;
    //             if(parseFloat(getComputedStyle(exhibitionDescriptionEl).height) > windowTreshold){
    //                 vc.showMore = true;
    //                 vc.needLessButton = true;
    //             }
    //             // gtag('event', "pinpage", {
    //             //     'event_category': 'momiapp',
    //             //     'event_label': 'pin',
    //             //     'value': ''
    //             // });
    //         });
    //     })
    // }

    created() {
        var exhibitionId = this.$route.params.exhibitionIdentifier.toString();

        Promise.all([
            ExhibitionClient.showDetail(exhibitionId),
            ExhibitionClient.getExhibitionSections(exhibitionId)
        ])
        .then(xs => {
            StorageServices.setExhibition(xs[0]);
            store.state.exhibition = xs[0];
            this.mostra = xs[0];
            this.sections = xs[1];

            if(this.sections.length == 0)
                this.showArtworksOrganized = false;

            this.$nextTick(() => {
                let exhibitionDescriptionEl = <HTMLElement>this.$refs.mostraDescription;
                let windowTreshold = window.innerHeight * 19 / 100;
                if(parseFloat(getComputedStyle(exhibitionDescriptionEl).height) > windowTreshold){
                    this.showMore = true;
                    this.needLessButton = true;
                }
            })
        })
    }

    openLocationAreaDetail(section: OM.ExhibitionSectionVM) {
        var exhibitionItems = getArts(section, this.mostra.artList, this.mostra.exhibitionItems);

        this.$opModal.show(LocationAreaDetailModal, {
            mostra: this.mostra,
            section: section,
            items: exhibitionItems,
            callback: (pin: string) => {
                this.$opModal.closeAll();
                this.searchForOpera(pin);
            }
        })
    }

    searchForOpera(pin: string) {
        this.showPinCodeOverlay = false;
        this.$router.push('/exhibition-item/' + pin);
    }
    searchForOperaByTitle(title: string) {
        this.showPinCodeOverlay = false;
        this.$router.push('/exhibition-item/search/' + title);
    }

}
